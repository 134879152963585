import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../store/feature/accountSlice';
import { get_data_by_current_language } from '../../utils/GetDataByLanguage';

const AccountShareButton = ({ data, styles }) => {
  const { account_details } = useSelector(accountSelector);
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div
        className="flex items-center   "
        style={get_dynamic_styles(styles?.box_styles)}
      >
        <button
          className="     rounded-button  flex items-center justify-center  gap-2 "
          type="button"
          style={get_dynamic_styles(styles?.button_styles)}
          onClick={() => {
            navigator
              .share({
                title: `${account_details?.name}`,
                url: `${window.location.href}`,
              })
              .then(() => {
                // alert('Thanks for sharing!');
              })
              .catch();
          }}
        >
          {data?.icon && data?.is_icon_in_right != true && (
            <img
              className="w-auto object-cover  "
              style={get_dynamic_styles(styles?.icon_styles)}
              src={data?.icon}
              alt="image"
            />
          )}
          {data?.button_title && (
            <p
              className="text-lg font-semibold text-gray-700"
              style={get_dynamic_styles(styles?.text_styles)}
            >
              {get_data_by_current_language({
                data_list: data?.translated_data,
                key_name: 'button_title',
                default_data: data?.button_title,
              })}
            </p>
          )}

          {data?.icon && data?.is_icon_in_right == true && (
            <img
              className="w-auto object-cover  "
              style={get_dynamic_styles(styles?.icon_styles)}
              src={data?.icon}
              alt="image"
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default AccountShareButton;
