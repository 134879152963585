import React, { memo } from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

import { getThumbnailImage } from '../Shared/Constant/Constant';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../store/feature/accountSlice';

const AccountThumbImage = ({ styles, data }) => {
  const { account_details } = useSelector(accountSelector);
  const image = account_details?.images[0];
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      <div style={get_dynamic_styles(styles?.box_styles)}>
        {image ? (
          <img
            style={get_dynamic_styles(styles?.image_styles)}
            src={getThumbnailImage(image)}
            onError={(e) => {
              e.target.src = image;
            }}
            alt="image"
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-[70px] w-[70px] rounded-full mr-6 text-gray-600 border p-5 border-text-gray-600 "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default memo(AccountThumbImage);
