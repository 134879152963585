import React, { useEffect, useState } from 'react';
import {
  accountSelector,
  getAccountListingsById,
} from '../../store/feature/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { storeSelector } from '../../store/feature/storeSlice';
import AccountListingsItem from './AccountListingsItem';
import Pagination from '../Shared/Pagination/Pagination';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import CustomLoading from '../Shared/Loading/CustomLoading';
import { Listing_text } from '../../constant/Translation/Listing';

const AccountListings = ({ styles, data }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const {
    account_details,
    account_listings,
    account_listings_page,
    account_listings_total_records,
  } = useSelector(accountSelector);

  useEffect(() => {
    if (account_details.id) {
      dispatch(
        getAccountListingsById({
          ...router.query,
          account_id: account_details.id,
          status: 2,
        })
      );
    }
  }, [account_details?.id]);

  // more listings
  const moreListings = (next_page) => {
    router.push({
      query: { ...router.query, page: next_page },
    });
  };

  //total page
  useEffect(() => {
    const totalpage = Math.ceil(account_listings_total_records / 30);

    if (Number(account_listings_total_records) > 30) {
      setPageCount(totalpage);
    }
  }, [account_listings_total_records]);

  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      {isDataLoading && <CustomLoading />}

      {account_listings === null || account_listings?.length > 0 ? (
        <AccountListingsItem
          Products={account_listings}
          account_details={account_details}
          setIsDataLoading={setIsDataLoading}
        />
      ) : (
        <div className=" w-full h-[200px] mt-5 flex justify-center items-start">
          <div
            className="w-full    md:w-5/6 bg-yellow-500    text-white px-4 py-3 rounded-card  relative grid grid-cols-[5%,80%]"
            role="alert"
          >
            <div className="flex items-center justify-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </div>
            <div className="ml-5">
              <span className="  ltr:ml-2 rtl:mr-2">
                {Listing_text.productlist.no_products_found}
              </span>
            </div>
          </div>
        </div>
      )}
      {account_listings?.length > 0 && (
        <div className="mt-12   flex justify-center pb-12 ">
          <Pagination
            pageCount={pageCount}
            onPageChange={moreListings}
            current_page={account_listings_page}
          />
        </div>
      )}
    </div>
  );
};

export default AccountListings;
