import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../store/feature/accountSlice';

const AccountOwnerName = ({ styles, data }) => {
  const { account_details } = useSelector(accountSelector);
  return (
    <div className="   " style={get_dynamic_styles(styles?.main_styles)}>
      <div style={get_dynamic_styles(styles?.box_styles)}>
        <p className="  " style={get_dynamic_styles(styles?.text_styles)}>
          @{account_details?.user?.first_name}{' '}
          {account_details?.user?.last_name}
        </p>
      </div>
    </div>
  );
};

export default AccountOwnerName;
