import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../store/feature/accountSlice';

const AccountStoreName = ({ styles, data }) => {
  const { account_details } = useSelector(accountSelector);
  return (
    <div className="   " style={get_dynamic_styles(styles?.main_styles)}>
      <div style={get_dynamic_styles(styles?.box_styles)}>
        <h1 className="  " style={get_dynamic_styles(styles?.text_styles)}>
          {account_details?.name}
        </h1>
      </div>
    </div>
  );
};

export default AccountStoreName;
