import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../store/feature/accountSlice';
import { Account_text } from '../../constant/Translation/Account';
import { user_plus } from '../Shared/Constant/Icons/AllIcons';

const AccountTotalFollowers = ({ styles, data }) => {
  const { account_details } = useSelector(accountSelector);
  const followers = account_details?.total_followers;
  return (
    <div
      className="flex items-center justify-start gap-1.5"
      style={get_dynamic_styles(styles?.box_styles)}
    >
      <div
        className="flex items-center justify-start gap-1.5"
        style={get_dynamic_styles(styles?.text_styles)}
      >
        <span className=" text-inherit">{user_plus}</span>
        <span>
          {Number(followers) > 1
            ? Account_text.storedetail.total_followers
            : Account_text.storedetail.total_follower}
          :
        </span>{' '}
        <span className="text-primary">{account_details?.total_followers}</span>
      </div>
    </div>
  );
};

export default AccountTotalFollowers;
