import React from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { useSelector } from 'react-redux';
import ReadingComposer from '../Shared/Composer/ReadingComposer';
import { accountSelector } from '../../store/feature/accountSlice';

const AccountDescriptions = ({ styles, data }) => {
  const { account_details } = useSelector(accountSelector);
  const description = account_details?.description;
  return (
    <div style={get_dynamic_styles(styles?.main_styles)}>
      {data?.is_show_normal ? (
        <p style={get_dynamic_styles(styles?.text_styles)}>
          {data?.max_words
            ? description?.slice(0, data?.max_words) + '...'
            : description}
        </p>
      ) : (
        <ReadingComposer
          contents={
            data?.max_words
              ? description?.slice(0, data?.max_words) + '...'
              : description
          }
        />
      )}
    </div>
  );
};

export default AccountDescriptions;
