import React, { memo } from 'react';
import { get_dynamic_styles } from '../../constant/DynamicStyles';

import { getThumbnailImage } from '../Shared/Constant/Constant';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../store/feature/accountSlice';

const AccountRawImage = ({ styles, data }) => {
  const { account_details } = useSelector(accountSelector);
  const image = account_details?.images[0];
  return (
    image && (
      <div style={get_dynamic_styles(styles?.main_styles)}>
        <div style={get_dynamic_styles(styles?.box_styles)}>
          <img
            style={get_dynamic_styles(styles?.image_styles)}
            src={image}
            onError={(e) => {
              e.target.src = image;
            }}
            alt="image"
          />
        </div>
      </div>
    )
  );
};

export default memo(AccountRawImage);
