export const PAGE_KEY = {
  listings: 'listings',
  home: 'home',
  subscription: 'subscription',
  account: 'accounts',
  listings_by_category: 'listings_by_category',
  checkout_success: 'checkout_success',
  error: 'error-page',
  account_details: 'account_details',
};
